<template>
   
    <div>
        <div v-if="showheader" class="headzone">
        <h3 :class="[datetab]"    > <a :class="[todaytabstatus]"  style="text-decoration:none" v-on:click="gettoday" >Today</a></h3>
        <h3 :class="[datetab,tomorrowtabstatus]" ><a :class="[tomorrowtabstatus]" style="text-decoration:none" v-on:click="gettomorrow" >Tomorrow</a>
        </h3>
        <h3 class="datetab"><a  :class="[pridetabstatus]"    style="text-decoration:none" v-on:click="getpride('today')" >Pride</a></h3>
        <!-- <h3 class="datetab" href="/reference"><a  style="text-decoration:none" >goal</a></h3> -->
      </div>
        <a-spin  v-if="showloading"   tip="Loading...">
        <a-alert
          description="tasks graphs is loading....."
        ></a-alert>
      </a-spin>
        <!-- <Treeview :node="treeData" /> -->
    <div id="tasktreedatapart">
      <!-- 使用这个组件，并绑定数据 -->
      <div class="miniselect" v-show="showpridetab">
         <!--仅会员展示  -->
        <button class="text pridebutton mpb"  id="mpb" v-show="showmonthtab"  v-on:click="getpride('month')">month</button>
        <button class="text pridebutton wpb" id="wpb"   v-on:click="getpride('week')">week</button>
        <button  class="text pridebutton tpb" id="tpb"  v-on:click="getpride('today')">today</button>
        <button  class="text pridebutton spb" id="spb"  v-on:click="getpride('summary')">summary</button>
        
    </div>
     
      <Taskrecursiontree v-show = "showpridetasks" @didi = "test"   :tasktype="tasktypedata" :showtoolbox="showtoolboxdata"    :showcheckbox="showcheckboxdata"       :model="treeData"/>
      <div class="textzone" v-show="!showpridetasks">{{ summarytext }}</div>
      <!-- <child-component @newValue="test" /> -->
    </div>
    <div class="addtaskicon"  v-if="showpridetab&&showemptyinfo">
      <span>🕓</span>还没有完成的任务哟</div>
    <div id="addbox" class="addsolobox" v-if="showsoloaddbox"></div>
    <div class="addtaskicon" @click="addsolobox" v-if="showpridetab==false">
      <span>+</span>快速添加新的任务</div>
    <div id="addbox" class="addsolobox" v-if="showsoloaddbox">
    <SoloTaskbox  :plantimePara ="plantimeParalocal"/>
     </div>
    </div>
</template>
<style scoped lang="scss">
.datetab{
  font-size: 20px;
}
.addtaskicon{
  text-align: left;
  margin-left:48px;
  padding: 3px;
  color:rgb(89, 94, 92);
  margin-right: 10px;
  margin-top:5px;
  span{
    color:red;
    margin-right: 9px;
  }
}

.addsolobox{
  position:fixed;
  z-index:1;
  top:10%;
  left:33%;
  right:33%;
  max-width: 500px;
  height:500px;
  background-color:white;
  opacity: 1;
  border:1px solid rgb(209, 200, 200);
  border-radius: 2px;
  /* box-shadow:  2px 2px rgb(209, 200, 200); */
}


.miniselect{
 margin-left:auto;

}

.pridebutton{
  border: none;
  color: white;
  &:focus{
    background: blue;
  }
}



.headzone{
  text-align: left;
  margin-left: 40px;
}
.datetab{
    font-weight: 800;
    display: inline-block;
    padding: 0cm;
    padding-right: 0px;
    margin-left:10px;
}

.tabactive{

    color:brown;
    font-size:40px !important;
    font-family: math;
}

#tasktreedatapart{
    display:flex;
    flex-direction: column;
    max-width: 40vw;
    text-align: left;
}

.textzone{
  margin-top:2px;
  margin-left:40px;
}
</style>
<script>

// import ChildComponent from "./childcomponent.vue";
import moment from 'moment';
import emitter from "../eventbus.js"; 
import configure_options from '../configure'
const axios = require('axios').default;
import SoloTaskbox from "./soloaddtaskbox.vue"
// import Treeview from './tasktree.vue'
import { computed } from 'vue';
import { useStore } from 'vuex';
import Taskrecursiontree from "./taskrecursiontree";
import {  ref } from 'vue';
export default {
    name: "tasktreecontainer",
    components: {
        // Treeview,
        Taskrecursiontree,
        SoloTaskbox,
        // ChildComponent 
    },
    props: ['showheader'],
    created(){
    emitter.on("refreshtasks",msg=>{
      
        console.log("HomeContent接收到得About发送得数据了：",msg);
        // alert("检测事件是否被激发today")
        if(msg=="today"){
          this.getdata("today")
       
        }
        if(msg=="tomorrow"){
          this.getdata("tomorrow")
        }
    });

    emitter.on("trunoffsoloaddbox",msg=>{
      
     
      // _this.options = msg
      if(msg=="fromsoloaddbox"){
        // alert("检查是否被盗用")
        // alert(5)
          console.log(1)
          this.showsoloaddbox = false
      }
      
  })

    },
    methods:{
        gettomorrow(){
          this.showpridetab = false;

          //这里将展开测试
          this.tasktypedata = "tomorrowtask"
          this.showcheckboxdata = true;
          this.showtoolboxdata = true;
          this.todaytabstatus = "no"
          this.pridetabstatus="no"
        this.tomorrowtabstatus = "tabactive"
        // alert("获取明天的消息")
        var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]
        this.showpridetasks = true
        axios.get(serverhost + "/v1/tomorrowjson").then(
       response=>{
       var data = response.data
           console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
      console.log(data);
      // console.log(status);
      // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
        var searchedtasks = data.task
          console.log("--------------")
        //   console.log(searchedtopics)
        var alltasksid = []
        var allparentid = []
          for(var i =0 ;i<searchedtasks.length;i++){
            searchedtasks[i].children = []
            alltasksid.push(searchedtasks[i].ID.toString())
            allparentid.push(searchedtasks[i].parentid)
          }
          console.log("++++++++++++++++")
          console.log(searchedtasks)
        //   console.log(searchedtopics)
        //新建一个market字典
       //如何深入的
            function buildTree(array) {
            let tree = []
            for (let i = 0; i < array.length; i++) {
                console.log(i)
                console.log(alltasksid.includes(array[i].parentid))
                if(array[i].parentid=="unspecified"||alltasksid.includes(array[i].parentid)==false){
                   console.log("应该在第一层")

                   tree.push(array[i])
                }else{
                //将父亲节点弹出来
                let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop()
                console.log(array[i].parentid)
                console.log(parent);
                parent.children.push(array[i]);
                }


            }
            return tree
            }



        const tasktree= buildTree(searchedtasks)

        this.treeData = {
            name: "这是今天的任务系列,注意这里的数据",
            root:true,
            children:tasktree
        }

        console.log("final return")
        console.log(tasktree)



     }
   ).catch(error=>{console.log(error)})
  
         }
        ,
        gettoday(){
          this.showpridetab = false;
          this.showloading = true;
          this.tasktypedata = "todaytask"
          this.showtoolboxdata = true;
          this.showcheckboxdata = true;
        this.todaytabstatus = "tabactive"
        this.tomorrowtabstatus = "no"
        this.pridetabstatus = "no"
        this.showpridetasks = true
        // alert("获取明天的消息")
          var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]


        axios.get(serverhost+"/v1/edge/todayjson").then(
        response=>{
       var data = response.data
           console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
      console.log(data);
      // console.log(status);
      // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
       
      
      var amazingday =  data.amazingday
      var amazingcount = data.amazingcount > 5 ? 5 : data.amazingcount;

      // alert(`amazing is ${amazingday}`)
      if(amazingday){
        emitter.emit("amazingsignal",{type:"amazingsignal",
        amazingday:amazingday,
        amazingcount:amazingcount
      
      
      })
      }
      
      var searchedtasks = data.task
          console.log("--------------")
        //   console.log(searchedtopics)
        var alltasksid = []
        var allparentid = []
          for(var i =0 ;i<searchedtasks.length;i++){
            searchedtasks[i].children = []
            alltasksid.push(searchedtasks[i].ID.toString())
            allparentid.push(searchedtasks[i].parentid)
            // 尝试在这里加到里面去
            // searchedtopics[i].folder = false
            // if(searchedtopics[i].subKeys.length>0){
            //   searchedtopics[i].folder  = true
            // }
            // console.log(searchedtopics[i].key,searchedkey)
            // if (searchedtopics[i].key==searchedkey){
            //   console.log("检查")
              
            //   searchedtopics[i].parentKey = null 
            // }
          }
          console.log("++++++++++++++++")
          console.log(searchedtasks)
        //   console.log(searchedtopics)
        //新建一个market字典
       //如何深入的
            function buildTree(array) {
            let tree = []
            for (let i = 0; i < array.length; i++) {
                // if (array[i].parentid) {
                // //如果undifined
                // let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop()
                // console.log(parent);
                // parent.children.push(array[i]);
                // } else {
                // tree.push(array[i])
                // }
                console.log(i)
                console.log(alltasksid.includes(array[i].parentid))
                if(array[i].parentid=="unspecified"||alltasksid.includes(array[i].parentid)==false){
                   console.log("应该在第一层")

                   tree.push(array[i])
                }else{
                //将父亲节点弹出来
                let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop()
                console.log(array[i].parentid)
                console.log(parent);
                parent.children.push(array[i]);
                }


            }
            return tree
            }



        const tasktree= buildTree(searchedtasks)

        this.treeData = {
            name: "这是今天的任务系列,注意这里的数据",
            root:true,
            children:tasktree
        }
        this.showloading = false
        console.log("final return")
        console.log(tasktree)



     }
   ).catch(error=>{console.log(error)})
  
        }
        ,
        getpride(datetype){
          this.showpridetab= true;
          this.showloading = true;
          this.tasktypedata = "pridetask"
        this.showtoolboxdata = false;
        this.showcheckboxdata = false;
        this.showemptyinfo = false;
        this.todaytabstatus = "no"
        this.tomorrowtabstatus = "no"
        this.pridetabstatus = "tabactive"
        // alert("获取明天的消息")
          var env =  configure_options["env"]
         
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]
        function setButtonBackgroundColor(datetype) {
  const buttons = {
    week: ["wpb", "tpb", "mpb", "spb"],
    today: ["tpb", "wpb", "mpb", "spb"],
    month: ["mpb", "wpb", "tpb", "spb"],
    summary: ["spb", "wpb", "tpb", "mpb"]
  };

  // 获取当前日期类型对应的按钮类名
  const selectedButtons = buttons[datetype] || buttons.today; // 默认值是 'today'

  // 重置所有按钮背景色为灰色
  buttons[datetype].forEach(buttonClass => {
    document.getElementsByClassName(buttonClass)[0].style.backgroundColor = "grey";
  });

  // 设置选定按钮的背景色为蓝色
  document.getElementsByClassName(selectedButtons[0])[0].style.backgroundColor = "blue";
}

// 设置 pridetypejson
const pridetypejsonMap = {
  week: "weekpridejson",
  today: "todaypridejson",
  month: "monthpridejson",
  summary: "summarypridejson"
};

// 获取 pridetypejson
var pridetypejson = pridetypejsonMap[datetype] || "todaypridejson"; // 默认值是 'todaypridejson'

// 调用函数设置背景色
setButtonBackgroundColor(datetype);

         var _this =this
        axios.get(serverhost+"/v1/pride",{headers:{"client":pridetypejson}}).then(
        response=>{
        //在这里搜集到会员信息
        _this.showloading = false;

        _this.showpridetasks = true
        var data = response.data
        _this.userlevel = data.userlevel
        var typeofresponse =  data.type

        if(typeofresponse=="text"){
        _this.summarytext = data.summarytext
        _this.showpridetasks = false
        _this.showloading = false
        return 
        }

       


        if(_this.userlevel=="high"){
          _this.showmonthtab = true
        }else{
          _this.showmonthtab = false
        }
        console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
        console.log(data);

        var pridetasks=[]

        

        if (data["memories"] && Array.isArray(data["memories"])) {
          for(var t=0;t<data["memories"].length;t++){
          for(var y=0;y<data["memories"][t]["Alldays"].length;y++){
            pridetasks.push(data["memories"][t]["Alldays"][y])
          }
          console.log(data["memories"][t]["Alldays"])
        }
        }else{
          _this.showemptyinfo = true
          _this.treeData = {
            name: "还没有完成的任务哟",
            root:true,
            children:[]
        }
        return

        }
      
        console.log("--------------")
        console.log(pridetasks)
        //   console.log(searchedtopics)
        var alltasksid = []
        var allparentid = []
        for(var i =0 ;i<pridetasks.length;i++){
            pridetasks[i].children = []
            alltasksid.push(pridetasks[i].ID.toString())
            allparentid.push(pridetasks[i].parentid)
            // 尝试在这里加到里面去
            // searchedtopics[i].folder = false
            // if(searchedtopics[i].subKeys.length>0){
            //   searchedtopics[i].folder  = true
            // }
            // console.log(searchedtopics[i].key,searchedkey)
            // if (searchedtopics[i].key==searchedkey){
            //   console.log("检查")
              
            //   searchedtopics[i].parentKey = null 
            // }
          }
          console.log("++++++++++++++++")
          console.log(pridetasks)
        //   console.log(searchedtopics)
        //新建一个market字典
       //如何深入的
            function buildTree(array) {
            let tree = []
            for (let i = 0; i < array.length; i++) {
                // if (array[i].parentid) {
                // //如果undifined
                // let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop()
                // console.log(parent);
                // parent.children.push(array[i]);
                // } else {
                // tree.push(array[i])
                // }
                console.log(i)
                console.log(alltasksid.includes(array[i].parentid))
                if(array[i].parentid=="unspecified"||alltasksid.includes(array[i].parentid)==false){
                   console.log("应该在第一层")

                   tree.push(array[i])
                }else{
                //将父亲节点弹出来
                let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop()
                console.log(array[i].parentid)
                console.log(parent);
                parent.children.push(array[i]);
                }


            }
            return tree
            }



        const tasktree= buildTree(pridetasks)

        this.treeData = {
            name: "这是今天的任务系列,注意这里的数据",
            root:true,
            children:tasktree
        }

        console.log("final return")
        console.log(tasktree)



     }
   ).catch(error=>{console.log(error)})
  
        },

       getsummary(){
        console.log(1)
       },

    },
    data() {
        return {
            showemptyinfo :false,
            showloading: true,
            datetab:"datetab",
            userlevel:"normal",
            summarytext:"👍",
            treeData:{},
            showpridetasks:true,
            showmodel:{show:true},
            showtoolboxdata:true,
            showcheckboxdata:true,
           
            showpridetab:false,
           

            // showheader:true,
        }
    },

    setup(){
      const store = useStore();
      const isVipUser = computed(() => {
  return store.getters.userLevel === 'vip';
});
      const selectedpride = ref('today');
      function test(){
         alert("测试数据")
       }
         function getdata(datetype){
        // alert("被调用")
        if(datetype=="today"){
        this.gettoday()
        }
         if(datetype=="tomorrow"){
          //  alert("被调用")
        this.gettomorrow()
        }
      }
   
     function addsolobox(){
      console.log("show plantime")
      // var datetab = "today"
      if(this.tasktypedata=="todaytask"){
        this.plantimeParalocal = moment().format("YYYY-MM-DD")
 
      }
      if(this.tasktypedata=="tomorrowtask"){
        this.plantimeParalocal = moment().add(1, 'days').format("YYYY-MM-DD")

      }
      this.showsoloaddbox=!this.showsoloaddbox
     }

      return{
        showsoloaddbox:ref(false),
        showmonthtab:ref(false),
        plantimeParalocal:ref("today"),
        getdata,
        isVipUser,
        tasktypedata:ref("todaytask"),
        test,
        addsolobox,
        selectedpride,
      }
    },
     mounted(){
    var _this = this
    _this.showloading = true;
    _this.todaytabstatus = "tabactive"
    _this.tomorrowtabstatus = "no"
       var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]
    var baseurl ="/v1/edge/todayjson"
        if(this.isVipUser){
          // 增加edge即为测试
          baseurl ="/v1/edge/todayjson"
        }else{
          console.log("normal api")
        }
    axios.get( serverhost+baseurl).then(
     response=>{
       var data = response.data
           console.log("~~~~~~~~~~~~~~~我正在打印所有未完成的任务~~~~~~~~~")
      console.log(data);
      _this.items = data.task
      var amazing=     data.amazingday
      
      var amazingcount = data.amazingcount > 5 ? 5 : data.amazingcount;
      // alert(amazing)
      // if(amazing){
      //   emitter.emit("refreshtasksfinished",{type:"finishedtoday",
      //   amazingday:amazing,amazingcount:amazingcount})
      // }
    // alert(amazing)
    if(amazing){
        emitter.emit("amazingsignal",{type:"amazingsignal",
        amazingday:amazing,amazingcount:amazingcount})
      }

      
      // console.log(status);
      // 提取所有的Goal = [{goal1的所有task},{goal2的所有task},{}]
        var searchedtasks = data.task
          console.log("--------------")
        //   console.log(searchedtopics)
        var alltasksid = []
        var allparentid = []
          for(var i =0 ;i<searchedtasks.length;i++){
            searchedtasks[i].children = []
            alltasksid.push(searchedtasks[i].ID.toString())
            allparentid.push(searchedtasks[i].parentid)
            // 尝试在这里加到里面去
            // searchedtopics[i].folder = false
            // if(searchedtopics[i].subKeys.length>0){
            //   searchedtopics[i].folder  = true
            // }
            // console.log(searchedtopics[i].key,searchedkey)
            // if (searchedtopics[i].key==searchedkey){
            //   console.log("检查")
              
            //   searchedtopics[i].parentKey = null 
            // }
          }
          // console.log("++++++++++++++++")
          // console.log(searchedtasks)
        //   console.log(searchedtopics)
        //新建一个market字典
       //如何深入的
            function buildTree(array) {
            let tree = []
            for (let i = 0; i < array.length; i++) {
                // if (array[i].parentid) {
                // //如果undifined
                // let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop()
                // console.log(parent);
                // parent.children.push(array[i]);
                // } else {
                // tree.push(array[i])
                // }
                // console.log(i)
                // console.log(alltasksid.includes(array[i].parentid))
                if(array[i].parentid=="unspecified"||alltasksid.includes(array[i].parentid)==false){
                  //  console.log("应该在第一层")

                   tree.push(array[i])
                }else{
                //将父亲节点弹出来
                let parent = array.filter(elem => elem.ID.toString() === array[i].parentid).pop()
                console.log(array[i].parentid)
                console.log(parent);
                parent.children.push(array[i]);
                }


            }
            return tree
            }



        const tasktree= buildTree(searchedtasks)

        _this.treeData = {
            name: "这是今天的任务系列,注意这里的数据",
            root:true,
            children:tasktree
        }

        // console.log("final return")
        // console.log(tasktree)
        _this.showloading = false;


     }
   ).catch(error=>{console.log(error)})
  
  }
}
</script>

